<template>
  <div class="h-full w-full relative">
    <nav-bar>
      <template slot="left">
        <back-navigation-item @trigger="popView()" />
      </template>
    </nav-bar>

    <!-- Resource found -->
    <div v-if="coupon" class="pt-content page-content">
      <img :src="coupon.heroImage" v-if="coupon.heroImage" class="w-full" />

      <div class="px-4 ">
        <div class="container max-w-sm mx-auto mt-6 pt-10 pb-6 px-4 bg-white rounded-3xl shadow-offer">
          <Offline>
            <div class="w-full bg-black p-4 my-3">
              Din enhed er ikke forbundet til internettet, og derfor vil du ikke kunne indløse kuponner.
            </div>
          </Offline>
          <div>
            <h2 class="text-center font-display font-extrabold uppercase text-xxl text-dark">{{ coupon.title }}</h2>
            <div v-html="coupon.description" class="text-center mt-2"/>
            <img :src="coupon.store.logo" v-if="coupon.store && coupon.store.logo" class="logo mx-auto mt-4" />
          </div>
        </div>
      </div>

      <div>
        <div class="container max-w-sm mx-auto px-10 py-8">
          <div v-if="!$store.state.offline">
            <button class="bg-green text-white rounded-lg p-4 w-full" v-if="!coupon.redeemed" @click="redeemCoupon()">Indløs rabatkupon</button>
            <button class="bg-green text-white rounded-lg p-4 w-full opacity-50" v-if="coupon.redeemed">Allerede indløst</button>
          </div>
          <div v-else>
            <button class="bg-green text-white rounded-lg p-4 w-full bg-opacity-50" v-if="!coupon.redeemed">Opret forbindelse til internetter for at indløse</button>
            <button class="bg-green text-white rounded-lg p-4 w-full opacity-50" v-if="coupon.redeemed">Allerede indløst</button>
          </div>

          <div class="flex flex-col text-sm text-center italic text-light-grey mt-4" v-html="coupon.validity" />
        </div>
      </div>
    </div>

    <!-- Resource not found -->
    <div v-else class="flex h-full w-full justify-center items-center">
      <div>Indholdet blev ikke fundet</div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import Offline from 'v-offline'
import { Coupon } from '../data/models/Coupon'
import popFromStackMixin from '../mixins/popFromstack'
import BackNavigationItem from '@/components/BackNavigationItem'
import requiresAuthMixin from '@/mixins/requiresAuth'
import { mapState } from 'vuex'
import { dataLayer } from '@/data/dataLayer'

export default {
  name: 'CouponDetail',
  metaInfo () {
    const coupon = this.$store.getters.getCouponById(parseInt(this.id))

    return {
      title: coupon ? `${coupon.title} | ${coupon.store.title}` : 'Kupon'
    }
  },
  mixins: [
    popFromStackMixin,
    requiresAuthMixin
  ],
  components: {
    BackNavigationItem,
    NavBar,
    Offline
  },
  props: {
    id: {
      required: true
    }
  },
  computed: {
    coupon () {
      return this.$store.getters.getCouponById(parseInt(this.id)) ?? null
    }
  },
  methods: {
    redeemCoupon () {
      this.$emit('show-redeem', this.coupon.id)

      // Emit data layer event
      dataLayer.push({
        event: 'show-redeem-coupon',
        coupon: this.coupon
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: 130px;
  height: 74px;
  object-fit: contain;
  object-position: center center;
}
.page-content{
  padding-bottom: 90px;
}
</style>

<template>
  <div class="bg-white fixed bottom-0 w-full  pb-0-safe pb-safe ">
    <div class="w-full flex px-4 py-4 tabbar-nav">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabBar'
}
</script>

<style scoped>
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
</style>

<template>
  <a href="#" @click.prevent="$emit('trigger'); $store.commit('setShowMenu', true);">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 7H20M4 12H20M4 17H20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </a>
</template>

<script>
export default {
  name: 'BurgerNavigationItem'
}
</script>

<style scoped>

</style>

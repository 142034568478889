<template>
  <div id="app" :class="themeClass">
    <vue100vh class="text-gray-800 overflow-hidden absolute flex w-screen backdrop"
              :class="{ 'relative left-80': $store.state.showMenu }">
      <page-container>
        <transition :name="transitionName">
          <router-view @show-redeem="showRedeemCoupon($event)"/>
        </transition>
        <div class="overlay-bottom"></div>
      </page-container>
      <tab-bar
        v-if="!['auth.login', 'auth.register','auth.reset', 'auth.forgot', 'auth.callback', 'main','cookie'].includes($router.currentRoute.name)">
        <tab-bar-item
          display-mode="focus"
          title="Kupontilbud"
          :active="$router.currentRoute.name === 'coupons.index'"
          @tapped="selectTabBarItem('coupons.index')">
        </tab-bar-item>
        <div class="flex w-5/6 tabbar-right">
        <tab-bar-item
          title="Favoritter"
          :active="this.currentTab === 'coupons.favourites'"
          @tapped="selectTabBarItem('coupons.favourites')">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 17L6 20L7.5 14L3 9L9.5 8.5L12 3L14.5 8.5L21 9L16.5 14L18 20L12 17Z" stroke="currentColor"
                  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </tab-bar-item>
        <tab-bar-item
          title="Historik"
          :active="this.currentTab === 'coupons.history'"
          @tapped="selectTabBarItem('coupons.history')">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 7V12L15 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </tab-bar-item>
        <tab-bar-item
          title="Din profil"
          :active="$router.currentRoute.name === 'auth.profile'"
          @tapped="selectTabBarItem('auth.profile')">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.70227 15.75H14.2977V14.25H9.70227V15.75ZM14.2977 15.75C15.9282 15.75 17.25 17.0718 17.25 18.7023H18.75C18.75 16.2433 16.7567 14.25 14.2977 14.25V15.75ZM9.70227 14.25C7.24335 14.25 5.25 16.2433 5.25 18.7023H6.75C6.75 17.0718 8.07178 15.75 9.70227 15.75V14.25ZM14.25 9C14.25 10.2426 13.2426 11.25 12 11.25V12.75C14.0711 12.75 15.75 11.0711 15.75 9H14.25ZM12 11.25C10.7574 11.25 9.75 10.2426 9.75 9H8.25C8.25 11.0711 9.92893 12.75 12 12.75V11.25ZM9.75 9C9.75 7.75736 10.7574 6.75 12 6.75V5.25C9.92893 5.25 8.25 6.92893 8.25 9H9.75ZM12 6.75C13.2426 6.75 14.25 7.75736 14.25 9H15.75C15.75 6.92893 14.0711 5.25 12 5.25V6.75ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75Z"
              fill="currentColor"/>
          </svg>
        </tab-bar-item>
        </div>
      </tab-bar>

      <transition name="slide-up">
        <redeem-coupon
          :id="this.redeemCoupon"
          v-if="showRedeemPopup"
          @close-popup="closeRedeemPopup($event)"/>
      </transition>
    </vue100vh>

    <slide-navigation>
      <slide-navigation-item @tapped="selectSlideNavItem('how-to')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.75 16.99C12.75 16.5758 12.4142 16.24 12 16.24C11.5858 16.24 11.25 16.5758 11.25 16.99H12.75ZM11.25 17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17H11.25ZM11.25 14C11.25 14.4142 11.5858 14.75 12 14.75C12.4142 14.75 12.75 14.4142 12.75 14H11.25ZM8.27587 8.98654C8.16802 9.38647 8.40479 9.7981 8.80472 9.90595C9.20465 10.0138 9.61628 9.77702 9.72413 9.3771L8.27587 8.98654ZM11.25 16.99V17H12.75V16.99H11.25ZM14.25 9.90909C14.25 10.5071 13.8779 10.8232 13.0775 11.369C12.4012 11.8301 11.25 12.5419 11.25 14H12.75C12.75 13.4581 13.0988 13.1699 13.9225 12.6083C14.6221 12.1313 15.75 11.402 15.75 9.90909H14.25ZM12 7.75C13.2867 7.75 14.25 8.73398 14.25 9.90909H15.75C15.75 7.87091 14.0801 6.25 12 6.25V7.75ZM9.72413 9.3771C9.96738 8.47508 10.8917 7.75 12 7.75V6.25C10.2678 6.25 8.70934 7.37913 8.27587 8.98654L9.72413 9.3771ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75Z"
            fill="white"/>
        </svg>
        <span>Sådan gør du</span>
      </slide-navigation-item>
      <slide-navigation-item @tapped="selectSlideNavItem('about')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 5L3 3V19L9 21M9 5L15 3M9 5V21M15 3L21 5V21L15 19M15 3V19M15 19L9 21" stroke="white"
                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>Om Kundeklub</span>
      </slide-navigation-item>
      <slide-navigation-item @tapped="selectSlideNavItem('stores')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 10L12 3L20 10L20 20H15V16C15 15.2044 14.6839 14.4413 14.1213 13.8787C13.5587 13.3161 12.7957 13 12 13C11.2044 13 10.4413 13.3161 9.87868 13.8787C9.31607 14.4413 9 15.2043 9 16V20H4L4 10Z"
            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>Tarup Centers butikker</span>
      </slide-navigation-item>
      <slide-navigation-item @tapped="selectSlideNavItem('contact')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.5 5.5L10.5858 12.5858C11.3668 13.3668 12.6332 13.3668 13.4142 12.5858L20.5 5.5M4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5H4C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19Z"
            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>Kontakt</span>
      </slide-navigation-item>

      <template slot="bottom">
        <slide-navigation-item @tapped="selectSlideNavItem('cookie')">
          Cookie- og privatlivspolitik
        </slide-navigation-item>
      </template>
    </slide-navigation>
  </div>
</template>

<script>
import vue100vh from 'vue-100vh/src/vue100vh/vue100vh.vue'
import { Component, Vue } from 'vue-property-decorator'
import PageContainer from './components/PageContainer.vue'
import TabBar from './components/TabBar.vue'
import TabBarItem from '@/components/TabBarItem.vue'
import router from '@/router'
import { CouponRepository } from '@/data/repositories/CouponRepository'
import SlideNavigation from '@/components/SlideNavigation'
import { User } from '@/data/models/User'
import { APIClient } from '@/data/APIClient'
import Profile from '@/views/Profile'
import SlideNavigationItem from '@/components/SlideNavigationItem'
import RedeemCoupon from '@/views/RedeemCoupon'
import { ThemeAction } from '@/data/ThemeAction'
import { ContentAction } from '@/data/ContentAction'

const DEFAULT_TRANSITION = 'fade'
const DEFAULT_TRANSITION_MODE = 'out-in'

export default {
  name: 'App',
  metaInfo: {
    title: 'Forside',
    titleTemplate: '%s | Tarup Center kuponhæfte'
  },
  components: {
    RedeemCoupon,
    SlideNavigationItem,
    SlideNavigation,
    PageContainer,
    TabBar,
    TabBarItem,
    vue100vh
  },
  data () {
    return {
      error: null,
      transitionName: DEFAULT_TRANSITION,
      prevHeight: 0,
      transitionMode: DEFAULT_TRANSITION_MODE,
      transitionEnterActiveClass: '',
      showRedeemPopup: false,
      redeemCoupon: null,
      themeClass: 'generic'
    }
  },
  watch: {
    isOpen: {
      immediate: true,
      handler (isOpen) {
        if (isOpen) {
          document.body.style.setProperty('overflow', 'hidden')
        } else {
          document.body.style.removeProperty('overflow')
        }
      }
    }
  },
  methods: {
    selectTabBarItem (item) {
      this.$router.push({ name: item })
    },
    selectSlideNavItem (item) {
      this.$store.commit('setShowMenu', false)
      this.$router.push({ name: item })
    },
    updateOnlineStatus (event) {
      const {
        type
      } = event

      // Set status
      this.$store.commit('setOffline', !(type === 'online'))
    },
    beforeLeave (element) {
      this.prevHeight = getComputedStyle(element).height
    },
    enter (element) {
      const { height } = getComputedStyle(element)
      // eslint-disable-next-line no-param-reassign
      element.style.height = this.prevHeight
      setTimeout(() => {
        // eslint-disable-next-line no-param-reassign
        element.style.height = height
      })
    },
    afterEnter (element) {
      // eslint-disable-next-line no-param-reassign
      element.style.height = 'auto'
    },
    showRedeemCoupon (couponID) {
      console.log(couponID)
      this.redeemCoupon = couponID
      this.showRedeemPopup = true
    },
    closeRedeemPopup (redeemed) {
      if (redeemed) {
        this.$router.push({
          name: 'coupons.history'
        })
      }

      this.showRedeemPopup = false
      this.redeemCoupon = null
    },
    loadContens () {
      const cachedContents = sessionStorage.getItem('pageContents')
      if (!cachedContents) {
        // ✅ Use cached theme
        try {
          ContentAction.contents().then((contents) => {
            sessionStorage.setItem('pageContents', JSON.stringify(contents.data))
          })
        } catch (error) {
          console.error('Error loading contents:', error)
        }
      }
    }
  },
  computed: {},
  created () {
    // Setup route push transitions
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName || DEFAULT_TRANSITION

      if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length
        const fromDepth = from.path.split('/').length
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      }

      this.transitionMode = DEFAULT_TRANSITION_MODE
      this.transitionEnterActiveClass = `${transitionName}-enter-active`

      if (to.meta.transitionName === 'zoom') {
        this.transitionMode = 'in-out'
        this.transitionEnterActiveClass = 'zoom-enter-active'
        document.body.style.overflow = 'hidden'
      }

      if (from.meta.transitionName === 'zoom') {
        this.transitionMode = null
        this.transitionEnterActiveClass = null
        document.body.style.overflow = null
      }

      this.transitionName = transitionName

      next()
    })
  },
  mounted () {
    // Load data
    APIClient.client.get('/auth/info')
      .then((response) => {
        // Set user
        const user = User.fromAPI(response.data.user)
        this.$store.commit('user', User.fromAPI(response.data.user))

        // Load available coupons
        CouponRepository.getCoupons()

        // Store user
        localStorage.setItem('user', JSON.stringify(response.data.user))
      })
      .catch((error) => {
        if (error.response && error.response.status === 401 && this.$router.currentRoute.name !== 'auth.login' && this.$router.currentRoute.name !== 'auth.register' && this.$router.currentRoute.name !== 'auth.reset') {
          // Remove access token
          localStorage.removeItem('access_token')

          // Push to login
          this.$router.push({ name: 'auth.login' })

          return
        } else {
          this.$store.commit('setOffline', true)

          if (localStorage.getItem('user')) {
            this.$store.commit('user', User.fromAPI(JSON.parse(localStorage.getItem('user'))))
          }

          CouponRepository.getCachedCoupons()
        }

        this.error = error
      })

    const cachedTheme = sessionStorage.getItem('themeClass')
    if (cachedTheme) {
      // ✅ Use cached theme
      this.themeClass = cachedTheme
    } else {
      try {
        ThemeAction.theme().then((settings) => {
          console.log(settings)
          this.themeClass = settings.data.theme ?? 'generic'
          sessionStorage.setItem('themeClass', this.themeClass)
        })
      } catch (error) {
        console.error('Error loading theme:', error)
      }
    }
    this.loadContens()
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .page-container {
    background-image: url('./assets/bg_generic.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center top;

    @media screen and (max-width: 767px){
      background-size: 135%;
    }

     .small-header-mb{
      @media screen and (max-width: 767px){
        padding-top: 0.5rem;
        padding-bottom: 3.0rem;
      }
    }

    .big-header{
      @media screen and (max-width: 899px) {
        padding-top: 3.5rem;
        padding-bottom: 3rem;
      }
      @media screen and (max-width: 767px){
        padding-top: 3.5rem;
        padding-bottom: 3.0rem;
      }
      @media screen and (max-width: 500px){
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
      @media screen and (max-width: 390px){
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
    }

    img.img-logo{
       width: 30%;

      @media screen and (max-width: 767px) {
        width: 45%;
      }
    }

    .btn-primary{
      background-color: #1c4762!important;

      &:hover{
        background-color: #132b3f!important;
      }
    }
    .btn-link{
      color: #1c4762!important;
    }

    .pt-navbar {
      background-color: transparent;
    }

    .label-content {
      justify-items: flex-end;
      svg path{
        fill: #1c4762;
      }
    }
  }
  .bg-light-orange{
    background-color: #1c4762!important;
  }
  .bg-green{
    background-color: #1c4762;
  }
  .text-green{
    color: #2d6093;
  }

  .relative {
    .outer {
      background-color: #1c4762;
      border: 3px solid #1c4762;
    }
  }
  .tabbar-nav{
    @media screen and (max-width: 767px) {
      & > .items-center{
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
  .tabbar-right{
    justify-content: flex-end;
    .items-center{
      width: auto;
      flex-grow: 0;
      display: inline-flex;
      gap: 10px;
      min-width: 100px;
    }
    @media screen and (max-width: 600px) {
      justify-content: center;
      .items-center{
        min-width: 75px;
      }
    }

    @media screen and (max-width: 400px) {
      .items-center{
        min-width: 60px;
      }
    }
  }
  .box-container{
    background-color: #ccd9e1;
    border-radius: 6px;
    padding: 15px 25px;
    margin-top: 25px;

    ul li {
      margin: 4px 0;
    }

    .contact-info{
      li{
        display: flex;
        gap: 10px;
        align-items: center;
      }
      svg path{
        fill: #1c4762;
      }
    }
  }

  .bg-white.h-full{
    background-color: #efeff1;
  }

  &.summer_1{
    .page-container {
      background-image: url('./assets/bg_summer_1.jpg');

      .btn-primary{
        background-color: #6cc1de!important;

        &:hover{
          background-color: #85d1ed!important;
        }
      }
      .btn-link{
        color: #6cc1de!important;
      }

      .pt-navbar {
        background-color: transparent;
      }

      .label-content {
        justify-items: flex-end;
        svg path{
          fill: #6cc1de;
        }
      }
    }

    .bg-light-orange{
      background-color: #6cc1de!important;
    }
    .bg-green{
      background-color: #6cc1de;
    }
    .text-green{
      color: #6cc1de;
    }
    .box-container {
      background-color: rgb(158 207 235 / 40%);
      .contact-info{
        svg path{
          fill: #6cc1de;
        }
      }
    }
    .relative {
      .outer {
        background-color: #6cc1de;
        border: 3px solid #6cc1de;
      }
    }
    .bg-blue {
      background-color: rgb(108 193 222 / var(--tw-bg-opacity));
    }
  }

  &.summer_2{
    .page-container {
      background-image: url('./assets/bg_summer_2.jpg');

      .btn-primary{
        background-color: #6ebeba!important;

        &:hover{
          background-color: #cdeeed!important;
        }
      }
      .btn-link{
        color: #6ebeba!important;
      }

      .pt-navbar {
        background-color: transparent;
      }

      .label-content {
        justify-items: flex-end;
        svg path{
          fill: #6ebeba;
        }
      }
    }

    .bg-light-orange{
      background-color: #6ebeba!important;
    }
    .bg-green{
      background-color: #6ebeba;
    }
    .text-green{
      color: #6ebeba;
    }
    .box-container {
      background-color: rgb(110 190 186 / 40%);
      .contact-info{
        svg path{
          fill: #6ebeba;
        }
      }
    }
    .relative {
      .outer {
        background-color: #6ebeba;
        border: 3px solid #6ebeba;
      }
    }
    .bg-blue {
      background-color: rgb(205 238 237 / var(--tw-bg-opacity));
    }
  }

  &.summer_3 {
    .page-container {
      background-image: url('./assets/bg_summer_3.jpg');

      .btn-primary{
        background-color: #58aca9!important;

        &:hover{
          background-color: #d0eceb!important;
        }
      }
      .btn-link{
        color: #58aca9!important;
      }

      .pt-navbar {
        background-color: transparent;
      }

      .label-content {
        justify-items: flex-end;
        svg path{
          fill: #58aca9;
        }
      }
    }

    .bg-light-orange{
      background-color: #58aca9!important;
    }
    .bg-green{
      background-color: #58aca9;
    }
    .text-green{
      color: #58aca9;
    }
    .box-container {
      background-color: rgb(88 172 169 / 30%);
      .contact-info{
        svg path{
          fill: #58aca9;
        }
      }
    }
    .relative {
      .outer {
        background-color: #58aca9;
        border: 3px solid #58aca9;
      }
    }
    .bg-blue {
      background-color: rgb(88 172 169 / var(--tw-bg-opacity));
    }
  }

  &.summer_4 {
    .page-container {
      background-image: url('./assets/bg_summer_4.jpg');

      .btn-primary{
        background-color: #58aca9!important;

        &:hover{
          background-color: #d0eceb!important;
        }
      }
      .btn-link{
        color: #58aca9!important;
      }

      .pt-navbar {
        background-color: transparent;
      }

      .label-content {
        justify-items: flex-end;
        svg path{
          fill: #58aca9;
        }
      }
    }

    .bg-light-orange{
      background-color: #58aca9!important;
    }
    .bg-green{
      background-color: #58aca9;
    }
    .text-green{
      color: #58aca9;
    }
    .box-container {
      background-color: rgb(88 172 169 / 30%);
      .contact-info{
        svg path{
          fill: #58aca9;
        }
      }
    }
    .relative {
      .outer {
        background-color: #58aca9;
        border: 3px solid #58aca9;
      }
    }
    .bg-blue {
      background-color: rgb(88 172 169 / var(--tw-bg-opacity));
    }
  }

  &.summer_5 {
    .page-container {
      background-image: url('./assets/bg_summer_5.jpg');

      .btn-primary{
        background-color: #e6878c!important;

        &:hover{
          background-color: #ffe2e3!important;
        }
      }
      .btn-link{
        color: #e6878c!important;
      }

      .pt-navbar {
        background-color: transparent;
      }

      .label-content {
        justify-items: flex-end;
        svg path{
          fill: #e6878c;
        }
      }
    }

    .bg-light-orange{
      background-color: #e6878c!important;
    }
    .bg-green{
      background-color: #e6878c;
    }
    .text-green{
      color: #e6878c;
    }
    .box-container {
      background-color: rgb(230 135 140 / 30%);
      .contact-info{
        svg path{
          fill: #e6878c;
        }
      }
    }
    .relative {
      .outer {
        background-color: #e6878c;
        border: 3px solid #e6878c;
      }
    }
    .bg-blue {
      background-color: rgb(230 135 140 / var(--tw-bg-opacity));
    }
  }

  &.summer_6{
    .page-container {
      background-image: url('./assets/bg_summer_6.jpg');

      .btn-primary{
        background-color: #e6826e!important;

        &:hover{
          background-color: #ffe3df!important;
        }
      }
      .btn-link{
        color: #e6826e!important;
      }

      .pt-navbar {
        background-color: transparent;
      }

      .label-content {
        justify-items: flex-end;
        svg path{
          fill: #e6826e;
        }
      }
    }

    .bg-light-orange{
      background-color: #e6826e!important;
    }
    .bg-green{
      background-color: #e6826e;
    }
    .text-green{
      color: #e6826e;
    }
    .box-container {
      background-color: rgb(230 130 110 / 30%);
      .contact-info{
        svg path{
          fill: #e6826e;
        }
      }
    }
    .relative {
      .outer {
        background-color: #e6826e;
        border: 3px solid #e6826e;
      }
    }
    .bg-blue {
      background-color: rgb(230 130 110 / var(--tw-bg-opacity));
    }
  }

  &.summer_7{
    .page-container {
      background-image: url('./assets/bg_summer_7.jpg');

      .btn-primary{
        background-color: #ffaa73 !important;

        &:hover{
          background-color: #fff1d8!important;
        }
      }
      .btn-link{
        color: #ffaa73 !important;
      }

      .pt-navbar {
        background-color: transparent;
      }

      .label-content {
        justify-items: flex-end;
        svg path{
          fill: #ffaa73 ;
        }
      }
    }

    .bg-light-orange{
      background-color: #ffaa73 !important;
    }
    .bg-green{
      background-color: #ffaa73 ;
    }
    .text-green{
      color: #ffaa73 ;
    }
    .box-container {
      background-color: rgb(255 241 216 / 60%);
      .contact-info{
        svg path{
          fill: #ffaa73 ;
        }
      }
    }
    .relative {
      .outer {
        background-color: #ffaa73 ;
        border: 3px solid #ffaa73 ;
      }
    }
    .bg-blue {
      background-color: rgb(255 170 115 / var(--tw-bg-opacity));
    }
  }
  .backdrop {
    background-color: #efeff1;
    //background-image: url('assets/background.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: height, opacity;
    transition-timing-function: ease;
    overflow: hidden;
    width: inherit;
    height: inherit;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
    width: inherit;
    height: inherit;
  }

  .slide-left-enter-active,
  .slide-left-leave-active,
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition-duration: 0.5s;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    overflow: hidden;
  }

  .slide-left-enter,
  .slide-right-leave-active {
    opacity: 0;
    transform: translate(2em, 0);
    width: inherit;
    height: inherit;
  }

  .slide-left-leave-active,
  .slide-right-enter {
    opacity: 0;
    transform: translate(-2em, 0);
    width: inherit;
    height: inherit;
  }

  .zoom-enter-active,
  .zoom-leave-active {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: zoom;
    width: inherit;
    height: inherit;
  }

  .zoom-leave-active {
    animation-direction: reverse;
  }

  .slide-up-enter-active {
    transition: all .35s ease;
  }

  .slide-up-leave-active {
    transition: all .35s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-up-enter, .slide-fade-leave-to {
    transform: translateY(100vh);
    opacity: 0;
  }

  @keyframes zoom {
    from {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
    100% {
      opacity: 1;
    }
  }
}
.pb-20.px-4 svg > path {
  fill: #eea19e;
}
.pb-20.px-4 .btn-log-out svg > path{
  fill: none;
}

.d-md{
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    &-none{
      display: none;
    }
  }
}
</style>

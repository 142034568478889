<template>
  <div class="h-full bg-light-blue page-container">
    <nav-bar>
      <template slot="left">
        <burger-navigation-item />
      </template>
    </nav-bar>
    <div>
      <div class="container mx-auto py-14 small-header-mb">
        <img src="../assets/logo-white.svg" class="w-1/2 mx-auto img-logo d-md-none"/>
      </div>
    </div>
    <div class="bg-white h-full rounded-t-3xl">
    <div class="container max-w-sm mx-auto pt-content pb-20 px-4">
      <div class="mb-3">
        <h1 class="text-3xl font-display text-green text-center page-headline" v-html="pageTitle"></h1>
      </div>
      <div class="mt-8">
        <div v-html="description">
        </div>
        <div class="box-container">
          <ul class="contact-info">
            <li v-if="telephone"><svg width="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.36 11.36">
              <path d="M0,1.34c0-.33.13-.66.35-.9.25-.28.61-.43.99-.43h1.79c.68,0,1.25.51,1.32,1.18.05.4.13.79.25,1.17.14.48.02.99-.33,1.34l-.73.73c.76,1.39,1.92,2.55,3.31,3.31l.73-.73c.35-.35.86-.47,1.33-.33.38.12.78.2,1.17.24.68.08,1.18.65,1.18,1.33v1.79c0,.37-.16.73-.43.98-.28.25-.65.38-1.03.34-1.28-.12-2.52-.46-3.69-1.01-1.13-.54-2.15-1.26-3.04-2.15-.89-.89-1.61-1.91-2.15-3.04C.47,3.99.13,2.75,0,1.46v-.12h0Z"/>
            </svg><a :href="'tel:' + telephone">{{ telephone }}</a></li>
            <li v-if="email"><svg width="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.85 8">
              <path d="M.17.09h.02s0-.02,0-.02h.02s.01-.02.01-.02h.02s.01-.02.01-.02h.02s.02-.01.02-.01h.02s.01-.01.01-.01h.02s.02,0,.02,0h.02s.02,0,.02,0h.03s10.96,0,10.96,0h.04s0,0,0,0h.03s.01,0,.01,0h.02s.02,0,.02,0h.02s.01.01.01.01h.02s.01.02.01.02h.02s.02.02.02.02h.01s.02.02.02.02h0s.01.02.01.02l-5.44,5.45c-.09.08-.2.13-.32.13s-.23-.05-.31-.13L.16.1h.01ZM0,1.19l2.81,2.81L0,6.81V1.19h0ZM11.4,8H.45c-.1,0-.2-.04-.28-.1l3.26-3.26,1.55,1.54c.25.25.59.39.94.39s.69-.14.94-.39l1.56-1.55,3.27,3.27c-.08.06-.18.1-.29.1h0ZM11.85,6.8l-2.8-2.8,2.8-2.8v5.6h0Z"/>
            </svg><a :href="'mailto:' + email">{{ email }}</a></li>
            <li v-if="website"><svg width="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.55 13.45">
              <path d="M12.72,10.01h-2.26c.31-.88.49-1.84.53-2.83h2.56c-.07,1.02-.36,1.99-.83,2.83h0ZM6.77,13.45c-.96-.57-1.75-1.46-2.33-2.54h4.66c-.57,1.08-1.37,1.97-2.33,2.54h0ZM4.04,10.01c-.33-.87-.54-1.83-.58-2.83h6.63c-.05,1.01-.25,1.97-.58,2.83h-5.47ZM0,7.18h2.56c.04.99.22,1.95.52,2.83H.83c-.47-.85-.77-1.81-.83-2.83h0ZM.83,3.44h2.25c-.3.88-.48,1.84-.52,2.83H0c.06-1.02.36-1.98.83-2.83h0ZM6.77,0c.96.57,1.76,1.46,2.33,2.54h-4.66c.58-1.08,1.37-1.97,2.33-2.54h0ZM9.51,3.44c.33.87.53,1.83.58,2.83H3.46c.04-1.01.25-1.96.58-2.83h5.47ZM13.55,6.28h-2.56c-.04-.99-.22-1.95-.53-2.83h2.26c.47.85.76,1.81.83,2.83h0ZM10.1,2.54c-.43-.93-1-1.74-1.68-2.4,1.49.37,2.78,1.23,3.7,2.4h-2.02ZM5.13.14c-.69.66-1.26,1.48-1.69,2.4H1.43C2.35,1.37,3.64.51,5.13.14h0ZM3.44,10.91c.43.93,1,1.74,1.69,2.4-1.49-.37-2.78-1.23-3.7-2.4h2.01ZM8.42,13.32c.68-.66,1.25-1.48,1.68-2.4h2.02c-.92,1.17-2.21,2.03-3.7,2.4h0Z"/>
            </svg><a :href="website">{{ website }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import BurgerNavigationItem from '@/components/BurgerNavigationItem'
import requiresAuthMixin from '../mixins/requiresAuth'
import { StoreRepostiory } from '@/data/repositories/StoreRepostiory'
import { ContentAction } from '@/data/ContentAction'

export default {
  name: 'Contact',
  metaInfo: {
    title: 'Kontakt'
  },
  mixins: [
    requiresAuthMixin
  ],
  components: {
    BurgerNavigationItem,
    NavBar
  },
  data () {
    return {
      pageTitle: '',
      description: '',
      telephone: '',
      email: '',
      website: ''
    }
  },
  methods: {
    loadContens () {
      const cachedContents = sessionStorage.getItem('pageContents')
      if (!cachedContents) {
        // ✅ Use cached theme
        try {
          ContentAction.contents().then((contents) => {
            sessionStorage.setItem('pageContents', JSON.stringify(contents.data))
            this.loadContent(contents.data)
          })
        } catch (error) {
          console.error('Error loading contents:', error)
        }
      } else {
        this.loadContent(JSON.parse(cachedContents))
      }
    },
    loadContent (contents) {
      if (contents) {
        const pageContent = contents.find(p => p.title.toLowerCase() === 'contact')
        if (pageContent) {
          this.pageTitle = pageContent.page_title
          this.description = pageContent.description
          this.telephone = pageContent.telephone
          this.email = pageContent.email
          this.website = pageContent.website
        }
      }
    }
  },
  mounted () {
    this.loadContens()
  }
}
</script>

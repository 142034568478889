<template>
  <div class="h-full bg-light-blue page-container">
    <nav-bar>
      <template slot="left">
        <burger-navigation-item/>
      </template>
    </nav-bar>
    <div>
      <div class="container mx-auto py-14 small-header-mb">
        <img src="../assets/logo-white.svg" class="w-1/2 mx-auto img-logo d-md-none"/>
      </div>
    </div>
    <div class="bg-white h-full rounded-t-3xl">
      <div class="container max-w-3xl mx-auto pt-content pb-20 px-4 store-container">
        <div class="mb-3">
          <h1 class="text-3xl font-display text-green text-center page-headline">Butikker</h1>
        </div>
        <div v-if="this.$store.getters.ready" class="mt-8 store-list">
          <div v-if="this.stores.length > 0">
            <div class="grid grid-cols-4 gap-4">
              <div
                v-for="(store) in stores"
                :key="store.id"
                class="bg-white shadow rounded-2xl h-28 p-4">
                <img :src="store.logo" class="logo"/>
              </div>
            </div>
          </div>
          <div v-else>
            Der blev ikke fundet nogle butikker
          </div>
        </div>
        <div v-else>
          Indlæser..
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import BurgerNavigationItem from '@/components/BurgerNavigationItem'
import requiresAuthMixin from '../mixins/requiresAuth'
import { StoreRepostiory } from '@/data/repositories/StoreRepostiory'
import { ThemeAction } from '@/data/ThemeAction'

export default {
  name: 'Stores',
  metaInfo: {
    title: 'Butikker'
  },
  mixins: [
    requiresAuthMixin
  ],
  components: {
    BurgerNavigationItem,
    NavBar
  },
  data () {
    return {
      loading: false,
      error: null
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    $route: 'fetchData',
    stores: {
      handler (val) {
        if (val.length !== 0) {
          this.loading = false
        }
      },
      immediate: true
    }
  },
  computed: {
    stores () {
      return this.$store.state.stores ?? []
    },
    usedTheme () {
      return this.stores.theme
    }
  },
  methods: {
    fetchData () {
      if (this.stores.length === 0) {
        // Handle offline state
        if (this.$store.state.offline) {
          StoreRepostiory.getCachedStores()
          this.loading = false
          return
        }

        StoreRepostiory.getStores()
          .catch((error) => {
            this.error = error
          })
          .finally(() => {
            this.loading = false
          })
      }
      this.loadThemeSettings()
    },
    loadThemeSettings () {
      ThemeAction.theme().then((settings) => {
        this.stores.theme = settings.theme ?? 'generic'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.store-container.max-w-3xl{
  @media screen and (max-width: 767px) {
    max-width: 24rem;

    .grid-cols-4 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}
.store-list{
  margin-bottom: 25px;
}
</style>

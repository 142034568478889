<template>
  <div class="h-full bg-light-blue page-container">
    <nav-bar>
      <template slot="left">
        <burger-navigation-item />
      </template>
    </nav-bar>
    <div>
      <div class="container mx-auto py-14 small-header-mb">
        <img src="../assets/logo-white.svg" class="w-1/2 mx-auto img-logo d-md-none"/>
      </div>
    </div>
    <div class="bg-white h-full rounded-t-3xl">
    <div class="container max-w-sm mx-auto pt-content pb-20 px-4">
      <div class="mb-3">
        <h1 class="text-3xl font-display text-green text-center page-headline">Historik</h1>
      </div>
      <Offline>
        <div class="w-full bg-black p-4 my-3">
          Din enhed er ikke forbundet til internettet, og derfor vil du ikke kunne indløse kuponner.
        </div>
      </Offline>
      <div v-if="this.$store.getters.ready">
        <div v-if="this.coupons.length > 0">
          <Coupon
            v-for="(coupon) in coupons"
            :key="coupon.id"
            :coupon="coupon"
          />
        </div>
        <div v-else>
          Du har ikke indløst nogle kuponer endnu.
        </div>
      </div>
      <div v-else>
        Indlæser..
      </div>
    </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Coupon from '@/components/Coupon'
import NavBar from '@/components/NavBar'
import Offline from 'v-offline'
import BurgerNavigationItem from '@/components/BurgerNavigationItem'
import requiresAuthMixin from '@/mixins/requiresAuth'

export default {
  name: 'CouponHistory',
  metaInfo: {
    title: 'Historik'
  },
  mixins: [
    requiresAuthMixin
  ],
  components: {
    BurgerNavigationItem,
    NavBar,
    Coupon,
    Offline
  },
  computed: {
    coupons () {
      return this.$store.state.coupons.filter(function (coupon) {
        return coupon.redeemed === true
      }) ?? []
    }
  }
}
</script>

<template>
  <div class="h-full bg-light-blue login page-container">
    <div>
      <div class="container mx-auto py-14 big-header">
        <img src="../assets/logo-white.svg" class="w-1/2 mx-auto img-logo"/>
      </div>
    </div>
    <div class="bg-white h-full rounded-t-3xl">
      <div class="container max-w-sm mx-auto pt-content pb-20 px-4">
        <div class="mb-3">
          <h1 class="text-3xl font-display text-green text-center page-headline">Log ind</h1>
        </div>
        <div v-if="error" class="text-center text-orange">
          {{ error }}
        </div>
        <div class="text-center">
          Du er nu ved at logge ind i kundeklubben og vil få adgang til de unikke VIP-tilbud.
        </div>
        <div v-if="loggingIn">
          <svg class="spinner" viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
          </svg>
        </div>
        <div class="px-4 py-4 flex flex-col" v-if="loggingIn === false">
          <div>
            <form @submit.prevent="handleSubmit">
              <div class="form-group">
                <input type="text" v-model="email" name="email" class="form-control" placeholder="E-mail"
                       :class="{'is-invalid': submitted && !email }"/>
                <div v-show="submitted && !email" class="invalid-feedback">E-mail er ikke gyldig</div>
              </div>
              <!--div class="form-group">
                <input type="password" v-model="password" name="password" placeholder="Adgangskode" class="form-control"
                       :class="{ 'is-invalid': submitted && !password }"/>
                <div v-show="submitted && !password" class="invalid-feedback">Adgangskode skal indeholde mindst otte tegn</div>
              </div-->
              <div class="form-group">
                <button class="btn btn-primary" :disabled="loggingIn">Login</button>
                <img v-show="loggingIn"/>
                <div class="text-center sign-up-text">
                  <!--router-link to="/forgot-password" class="btn btn-link">Glemt/opret kodeord</router-link-->
                  Er du ikke tilmeldt Tarup Centers Nyhedsbrev / Kundeklub, så kan du nemt og hurtigt tilmelde dig her ”<router-link to="/register" class="btn btn-link">Opret dig</router-link>”
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          <slide-navigation-item @tapped="selectSlideNavItem('cookie')">
            <div class="cookie-link">
            Cookie- og privatlivspolitik
            </div>
          </slide-navigation-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SlideNavigationItem from '@/components/SlideNavigationItem'
import { Providers, Facebook, Google } from 'universal-social-auth'
import { APIClient } from '@/data/APIClient'
import { User } from '@/data/models/User'
import { CouponRepository } from '@/data/repositories/CouponRepository'

export default {
  name: 'Login',
  metaInfo: {
    title: 'Login'
  },
  components: {
    SlideNavigationItem
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data () {
    return {
      responseData: {},
      hash: '',
      data: {},
      fauth: false,
      error: null,
      Facebook,
      Google,
      loggingIn: false,
      email: '',
      password: '',
      submitted: false
    }
  },
  methods: {

    selectSlideNavItem (item) {
      this.$store.commit('setShowMenu', false)
      this.$router.push({ name: item })
    },
    /**
     * Initiate authentication
     */
    useAuthProvider (provider, proData) {
      const pro = proData
      const ProData = pro || Providers[provider]

      // Authenticate
      this.$Oauth.authenticate(provider, ProData).then((response) => {
        const rsp = response
        this.loggingIn = true

        if (rsp.code) {
          this.responseData.code = rsp.code
          this.responseData.provider = provider
          this.responseData.redirectUri = `${window.location.origin}/auth/${provider}`
          this.useSocialLogin()
        }
      }).catch((err) => {
        this.showLoginError(err)
      })
    },

    /**
     * Handle successful oAuth callback
     */
    useSocialLogin () {
      // otp from input Otp form
      // hash user data in your backend with Cache or save to database
      const postData = {
        code: this.responseData.code,
        otp: this.data.tok,
        hash: this.hash,
        uri: this.responseData.redirectUri
      }
      // Post to auth controller
      APIClient.client.post('/auth/' + this.responseData.provider, postData)
        .then(async (response) => {
          if (response.status === 200) {
            await this.processLogin(response.data)
          } else {
            await this.showLoginError(response.data)
          }
        })
        .catch((error) => {
          this.showLoginError(error)
        })
    },

    /**
     * @param error{string|object} Response data
     */
    async showLoginError (error) {
      this.loggingIn = false
      console.log(error)
      this.error = error
    },

    /**
     * Handle successful login
     * @param response Response data
     * @returns {Promise<void>}
     */
    async processLogin (response) {
      this.loggingIn = true
      // Store access token
      localStorage.setItem('access_token', response.access_token)

      // Load user data
      APIClient.client.get('/auth/info')
        .then((response) => {
          // Set user
          this.$store.commit('user', User.fromAPI(response.data.user))

          // Store user
          localStorage.setItem('user', JSON.stringify(response.data.user))

          // Load available coupons
          CouponRepository.getCoupons()

          // Set online
          this.$store.commit('setOffline', false)
        })
        .catch((error) => {
          this.loggingIn = false
          this.error = error
        })
        // Push to coupon index
        .finally(() => {
          // Push to coupons
          this.$router.push({ name: 'coupons.index' })
        })
    },

    async handleSubmit (e) {
      this.submitted = true
      const { email, password } = this
      if (email) {
        const postData = {
          email: this.email,
          password: this.password
        }
        // Post to auth controller
        APIClient.client.post('/auth/signIn', postData)
          .then(async (response) => {
            console.log(response)
            if (response.status === 200) {
              await this.processLogin(response.data)
            } else {
              await this.showLoginError(response.data)
            }
            this.submitted = false
          })
          .catch((error) => {
            if (error.message.indexOf(500) !== -1) {
              this.showLoginError('E-mail findes ikke i systemet. Tryk på \'Opret konto\' og tilmeld dig nyhedsbrev.')
            } else {
              this.showLoginError(error)
            }

            this.submitted = false
          })
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  .login {
    //background-image: url('../assets/bg-polygon.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center 60%;
  }
  .big-header{
    @media screen and (max-width: 899px) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  .form-group {
    input {
      width: 100%;
      height: 44px;
      background: #e0dede;
      justify-content: center;
      display: flex;
      margin: 20px auto;
      padding: 10px;
      border: none;
      outline: none;
      border-radius: 5px;
    }

    button {
      margin: 10px auto;
      justify-content: center;
      display: block;
      color: #fff;
      background: #eea19e !important;
      font-size: 1em;
      font-weight: bold;
      margin-top: 20px;
      outline: none;
      border: none;
      border-radius: 5px;
      transition: .2s ease-in;
      cursor: pointer;
      padding: 10px 25px;
    }

    button:hover {
      background: #E0967B!important;
    }
  }

  .links-group {
    display: flex;
    width: 100%;
    justify-content: space-between;

    a {
      text-decoration: underline;
    }
  }

  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;

    & .path {
      stroke: hsl(210, 70, 75);
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }

  }
  .btn-link{
    text-decoration: underline;
  }
  .cookie-link{
    text-align: center;
    width: 100%;
    font-size: 13px;
    font-weight: 800;
    color: #b2b1b1;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  .text-center {
    text-align: center
  }
  .sign-up-text{
    padding-top: 1rem;
  }
}
</style>

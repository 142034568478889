<template>
  <div class="h-full bg-light-blue page-container">
    <nav-bar>
      <template slot="left">
        <burger-navigation-item/>
      </template>
    </nav-bar>
    <div>
      <div class="container mx-auto py-14 small-header-mb">
        <img src="../assets/logo-white.svg" class="w-1/2 mx-auto img-logo d-md-none"/>
      </div>
    </div>
    <div class="bg-white h-full rounded-t-3xl">
      <div class="container max-w-sm mx-auto pt-content pb-20 px-4">
        <div class="mb-3">
          <h1 class="text-3xl font-display text-green text-center page-headline" v-html="pageTitle"></h1>
        </div>
        <div class="mt-8" v-html="description">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import BurgerNavigationItem from '@/components/BurgerNavigationItem'
import requiresAuthMixin from '../mixins/requiresAuth'
import { StoreRepostiory } from '@/data/repositories/StoreRepostiory'
import { ContentAction } from '@/data/ContentAction'

export default {
  name: 'About',
  metaInfo: {
    title: 'Om kuponhæftet'
  },
  mixins: [
    requiresAuthMixin
  ],
  components: {
    BurgerNavigationItem,
    NavBar
  },
  data () {
    return {
      pageTitle: '',
      description: ''
    }
  },
  methods: {
    loadContens () {
      const cachedContents = sessionStorage.getItem('pageContents')
      if (!cachedContents) {
        // ✅ Use cached theme
        try {
          ContentAction.contents().then((contents) => {
            sessionStorage.setItem('pageContents', JSON.stringify(contents.data))
            this.loadCouponContent(contents.data)
          })
        } catch (error) {
          console.error('Error loading contents:', error)
        }
      } else {
        this.loadCouponContent(JSON.parse(cachedContents))
      }
    },
    loadCouponContent (contents) {
      if (contents) {
        const pageContent = contents.find(p => p.title.toLowerCase() === 'about')
        if (pageContent) {
          this.pageTitle = pageContent.page_title
          this.description = pageContent.description
        }
      }
    }
  },
  mounted () {
    this.loadContens()
  }
}
</script>

import { APIClient } from '@/data/APIClient'
import { ThemeAction } from '@/data/ThemeAction'

export class ContentAction {
  /**
   */
  static contents () {
    return APIClient.client.get('/contents/index', {})
  }
}

<template>
  <div class="h-full bg-light-blue page-container">
    <nav-bar>
      <template slot="left">
        <burger-navigation-item />
      </template>
    </nav-bar>
    <div>
      <div class="container mx-auto py-14 small-header-mb">
        <img src="../assets/logo-white.svg" class="w-1/2 mx-auto img-logo d-md-none"/>
      </div>
    </div><div class="bg-white h-full rounded-t-3xl">
    <div class="container max-w-sm mx-auto pt-content pb-20 px-4">
      <div class="mb-3">
        <h1 class="text-3xl font-display text-green text-center page-headline" v-html="pageTitle"></h1>
        <p class="text-black mt-2" v-if="coupons.length > 0" v-html="description"></p>
      </div>
      <Offline>
        <div class="w-full bg-black p-4 my-3">
          Din enhed er ikke forbundet til internettet, og derfor vil du ikke kunne indløse kuponner.
        </div>
      </Offline>
      <div v-if="this.$store.getters.ready" class="mt-8">
        <div v-if="this.coupons.length > 0">
          <Coupon
            v-for="(coupon) in coupons"
            :key="coupon.id"
            :coupon="coupon"
            @show-coupon="showCoupon(coupon)"
          />
        </div>
        <div v-else>
          Der er ikke nogle kuponer tilgængelig lige nu. Tjek igen senere.
        </div>
        <div class="w-full mb-4 mt-8 label-content">
        </div>
      </div>
      <div v-else>
        Indlæser..
      </div>
    </div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Coupon from '@/components/Coupon'
import NavBar from '@/components/NavBar'
import Offline from 'v-offline'
import BurgerNavigationItem from '@/components/BurgerNavigationItem'
import requiresAuthMixin from '../mixins/requiresAuth'
import { ContentAction } from '@/data/ContentAction'

export default {
  name: 'Coupons',
  metaInfo: {
    title: 'Tilbud'
  },
  mixins: [
    requiresAuthMixin
  ],
  components: {
    BurgerNavigationItem,
    NavBar,
    Coupon,
    Offline
  },
  data () {
    return {
      pageTitle: '',
      description: ''
    }
  },
  computed: {
    coupons () {
      return this.$store.state.coupons.filter(function (coupon) {
        return coupon.redeemed !== true
      }) ?? []
    }
  },
  methods: {
    showCoupon (coupon) {
      this.$router.push({
        name: 'coupons.show',
        params: {
          id: coupon.id
        }
      })
    },
    loadContens () {
      const cachedContents = sessionStorage.getItem('pageContents')
      if (!cachedContents) {
        // ✅ Use cached theme
        try {
          ContentAction.contents().then((contents) => {
            sessionStorage.setItem('pageContents', JSON.stringify(contents.data))
            this.loadCouponContent(contents.data)
          })
        } catch (error) {
          console.error('Error loading contents:', error)
        }
      } else {
        this.loadCouponContent(JSON.parse(cachedContents))
      }
    },
    loadCouponContent (contents) {
      if (contents) {
        const pageContent = contents.find(p => p.title.toLowerCase() === 'coupons')
        if (pageContent) {
          this.pageTitle = pageContent.page_title
          this.description = pageContent.description
        }
      }
    }
  },
  mounted () {
    this.loadContens()
  }
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import { Coupon } from '@/data/models/Coupon'
import { User } from '@/data/models/User'
import { Store } from '@/data/models/Store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    offline: false,
    showMenu: false,
    coupons: [] as Coupon[],
    stores: [] as Store[],
    user: null as User | null,
    theme: 'generic' as string
  },
  mutations: {
    setOffline (state, offline) {
      state.offline = offline
    },
    setShowMenu (state, showMenu) {
      state.showMenu = showMenu
    },
    coupons (state, coupons: Coupon[]) {
      state.coupons = coupons
    },
    stores (state, stores: Store[]) {
      state.stores = stores
    },
    user (state, user) {
      state.user = user
    },
    theme (state, theme) {
      state.theme = theme
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getCouponById: (state) => (id: number) => {
      return state.coupons.find(coupon => coupon.id === id)
    },
    // Check that data is loaded and the state indicates that everything is ready
    ready: (state) => {
      return (state.coupons.length > 0 || state.user !== null)
    }
  }
})
